
.ma-wishlist {
  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @screen smr {
      .btn.addToBag {
        padding: 1.8rem;
        width: 100%;
        max-width: auto !important;
      }
    }
  }
}
