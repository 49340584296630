
@import '../../../styles/variables';
@import '../../../styles/fonts.scss';

img {
  display: none;
}

.container-login-page {
  margin: 0 0 5.4rem;
  padding: 2.4rem 1.7rem 0;

  @screen lg {
    padding: 2.4rem 5.6rem 5rem;
  }
}

.box-btn-join-login {
  border-bottom: 0.1rem solid $alto;
  position: relative;

  &::after {
    content: '';
    width: 50%;
    height: 0.3rem;
    background-color: black;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    right: 50%;
    transition: right 500ms ease-out;
  }

  .box-join {
    button {
      color: $mine-shaft;
      font-weight: 500;
    }
  }

  .box-login {
    button {
      color: $emperor;
      font-weight: normal;

      @screen md {
        color: $mine-shaft;
      }
    }
  }

  &.tab-login-is-active {
    &::after {
      right: 0;
    }
    button {
      color: $emperor;
      font-weight: normal;

      @screen md {
        color: $mine-shaft;
      }
    }

    .box-login {
      button {
        color: $mine-shaft;
        font-weight: 500;
      }
    }
  }

  button {
    width: 100%;
    padding: 1.5rem 0;
    background: none;
    border: 0;
    position: relative;
    font-size: 1.6rem;

    //&.active {
    //  border-bottom: 0.3rem solid black;
    //  color: black;
    //  font-weight: 500;
    //}
  }
  .btn-join {
    &:after {
      content: '';
      width: 0.1rem;
      height: 2.4rem;
      position: absolute;
      background: $alto;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

::v-deep .box-my-account-slide {
  border-top: 1px solid $alto;
  padding-top: 2.4rem;
  margin: 4.8rem 11.1rem 2.4rem 11.1rem;
  @screen smr {
    margin: 2.4rem 0;
  }

  #sgh-slider {
    gap: 3.6rem;
  }

  .caption {
    text-align: center;
    color: $black;
    font-weight: 300;
    > p:first-child {
      width: 75%;
    }
    p {
      span {
        @include actaHeadlineFontTitle();
        line-height: 2.9rem;
        font-size: 2.4rem;
        font-weight: 300;
        padding-bottom: 0.8rem;
        display: block;
      }
      p {
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 400;
        color: $emperor;
      }
    }
  }

  .sgh-single-slide {
    @screen lgr {
      padding: 0 4rem;
    }
  }

  div#sgh-slider .nav-dot {
    height: 8px;
    width: 8px;
    border: 1px solid #222;
    background-color: transparent;
    margin-bottom: 0;
    &.active-slide {
      background-color: #111;
      opacity: 0.5;
    }
  }
}

@screen md {
  img {
    display: block;
  }

  .container-login-page {
    box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.05);
    margin: 5.5rem auto 16rem;
  }

  .components-login {
    .box-new-client {
      padding: 0 11.1rem;
    }
  }
}

.components-login {
  .btn-login {
    .sgh-my-account & {
      font-size: 1.4rem;
      min-width: 28rem;
      width: 28rem;
      height: 4rem;
    }
  }
}
