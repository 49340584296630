
@import '../../../styles/variables';
.loyalty-banner {
  width: 100%;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: $loyal-yellow;
  gap: 1.6rem;
  margin-top: -0.1rem;
  @screen lgr {
    width: 100vw;
    margin-left: -1.6rem;
  }
  .loyalty-banner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  &.guest {
    gap: 1.6rem;
    .loyalty-banner-wrapper {
      gap: 0.4rem;
    }
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: $black;
  }
  &__desc {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: $mine-shaft;

    &.is-loyalty-new-member {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
  button {
    color: $black;
    letter-spacing: 0;
  }
}

::v-deep .overlay-popup .container-popup {
  &.popup-checkout {
    .wrap-pop-inner {
      padding: 1.6rem 1.6rem 0 1.6rem;
      .sgh-popup-title {
        margin-top: 1.6rem;
      }
      .btn-close {
        position: absolute;
        padding: 1rem;
        right: 1rem;
        top: 1rem;
        margin: 0;
      }
    }
  }
}
::v-deep .container-popup:not(.popup-checkout) {
  max-width: 63.2rem;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.loyalty-image {
  margin-bottom: 0.8rem;
  img {
    width: 11.2rem;
  }
}

