
@import '../../../../../styles/variables';

.sgh-my-account-return-details {
  .btn {
    &.cta-secondary {
      color: $mine-shaft;
      border-color: $mine-shaft;
    }
    &.downloadInvoiceButton {
      width: 21.6rem;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @screen lgr {
        width: 100%;
      }
    }
  }
  .loyalty-order-banner {
    margin-top: -4rem;
    margin-bottom: 4rem;
  }
}
