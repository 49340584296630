
@import '../../../styles/variables';
@import '../../../styles/fonts';

.sgh-email-confirmation {
  min-height: 50.4rem;
  .icon-mail {
    background-size: cover;
    background-repeat: no-repeat;
  }
  &--content {
    max-width: 39.7rem;
    text-align: center;
    h1 {
      @include actaHeadlineFontTitle();
      letter-spacing: 0;
      text-align: center;
    }
    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      letter-spacing: 0;
      margin-top: 1.6rem;
      text-align: center;
    }
  }
}
