
@import '../../../styles/fonts.scss';
.recovery {
  .title {
    padding: 0;
    margin-bottom: 1.6rem;
    @include actaHeadlineFontTitle();
  }

  .description {
    padding: 0;
    margin-bottom: 3.4rem;
  }
}
.container-login-page {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  @screen lg {
    margin-top: 6rem;
    margin-bottom: 6rem;
    min-width: 50rem;
    transform: translateX(-50%);
  }
}
