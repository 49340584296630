
@import '../../../../../styles/variables';

.sgh-form-recovery {
  display: flex;
  flex-direction: column;
  align-items: center;

  .field {
    width: 37.5rem;

    @screen lgr {
      max-width: 31.5rem;
      width: 100%;
    }
  }

  .g-recaptcha {
    padding-top: 1.5rem;
  }

  .btn-recovery {
    &.cta-primary:hover {
      @screen lgr {
        background: $mine-shaft;
      }
    }
    padding: 0 1rem;
    min-width: 28rem;
  }
}
