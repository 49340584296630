
@import '../../../styles/variables';
@import '../../../styles/fonts';
::v-deep .sgh-myAccount-container {
  .breadcrumb-account {
    margin-bottom: 0 !important;
  }
}
.loyalty-page {
  margin-bottom: 7.2rem;
  @screen mdr {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  &__logo {
    .logo {
      width: 14.07rem;
      height: 2.409rem;
      position: relative;
      left: -0.2rem;
      margin-bottom: 3.2rem;
    }
  }
}

.confirmation-unsubscribe {
  .logo {
    width: 100%;
    height: 2.5rem;
  }
  &-title {
    @include actaHeadlineFontTitle();
    font-weight: 400;
    margin-top: 3.2rem;
    letter-spacing: 0.014rem;
  }
}
