
@import '../../../../../styles/variables';

.sgh-my-account-order-details {
  .btn {
    &.cta-secondary {
      color: $mine-shaft;
      border-color: $mine-shaft;
      &:hover {
        color: $white;
      }
      @screen lgr {
        &:hover {
          background-color: $white;
          color: $mine-shaft;
        }
        &:active {
          background-color: $black;
          color: $white;
        }
      }
    }
    width: 21.6rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @screen lgr {
      width: 100%;
    }
  }
  .footer {
    margin-bottom: 7.1rem;
  }
  ::v-deep .billing-address > span {
    text-decoration: underline;
    font-weight: normal;
    font-size: 1.6rem;
  }
  ::v-deep .sgh-dropdown-information {
    font-weight: 300;
  }

  ::v-deep .sgh-upload-Prescription .btn {
    color: #fff;
    background: #000000;
    border: solid 1px #000000;
  }
  ::v-deep .overlay-popup {
    .container-popup {
      width: 100%;
      max-width: 69.7rem;

      .wrap-pop-inner {
        padding: 8rem 1.6rem 4rem;
        @screen lg {
          padding: 10rem 6.4rem 5.7rem;
        }
        .content-inner-popup {
          & > * {
            padding: 0;
          }
        }

        .btn-close {
          position: absolute;
          top: 2.63rem;
          right: 2.63rem;
          z-index: 100;
        }
      }
    }
  }
  .loyalty-order-banner {
    margin-top: -4rem;
    margin-bottom: 4rem;
    @screen lgr{
      margin-top: -1.6rem;  
    }
  }
}
