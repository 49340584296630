
@import '../../../styles/variables';
@import '../../../styles/fonts.scss';

.sgh-myAccount-thanks {
  padding: 7.1rem 0;

  @screen md {
    padding: 9.4rem 0 9.5rem;
  }

  &-container {
    width: 33rem;
    @screen md {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 35rem;
      border-radius: 3px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
      background-color: $white;
    }
  }

  @screen md {
    &-container {
      width: 50rem;
      height: 27.6rem;
    }
  }
  .picture {
    display: none;
    @screen md {
      display: block;

      picture {
        height: 46.5rem;
      }
    }
  }
  .title {
    @include actaHeadlineFontTitle();
    text-align: center;
    color: #000;
  }

  .subtitle {
    margin-top: 1.6rem;
    font-size: 1.4rem;
    text-align: center;
  }

  .cta-container {
    margin-top: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      width: 28rem;
      height: 4rem;
      padding: 0 12px;
      @screen md {
        height: 3.8rem;
        width: 19rem;
      }
      @screen mdr {
        min-width: 10rem;
        white-space: normal;
      }
    }

    @screen md {
      margin-top: 3.6rem;
      flex-direction: row;
    }

    a {
      margin: 0;
      width: 100%;
      padding: 1.2rem 0.9rem;
      &:first-child {
        color: $mine-shaft;
        @screen mdr {
          margin-right: 1.6rem;
        }
      }

      @screen md {
        margin: 0 0.8rem;

        &:last-child {
          margin-top: 0;
        }
      }
    }
  }
}
