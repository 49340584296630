
@import '../../../styles/variables';
@import '../../../styles/fonts.scss';

h2 {
  padding-bottom: 3.2rem;
  @include actaHeadlineFontTitle();
  color: $black;

  @screen lg {
    padding-bottom: 2.4rem;
  }

  @screen lgr {
    &.has-banner-profile {
      padding-bottom: 2.4rem;
    }
  }
}

.banner-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 136px;
  padding: 0 1.2rem 0 1.6rem;
  background: $mine-shaft;
  color: $white;
  margin-bottom: 4rem;

  @screen lg {
    height: 76px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }

  .title {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-bottom: 0.8rem;

    @screen lg {
      margin-bottom: 0.4rem;
    }

    .incomplete {
      width: 0.8rem;
      height: 0.8rem;
      background: $pumpkin;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-left: 0.2rem;
    }
  }

  .subtitle {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 300;
    margin: 0;
  }

  .btn {
    font-size: 1.4rem;
    padding: 1.1rem 2rem;
    min-width: auto;
  }
}

.overview-banner {
  padding-bottom: 4rem;
  @screen lg {
    flex-wrap: wrap;
  }
  ::v-deep .cta-secondary {
    color: $mine-shaft;
    border-color: $mine-shaft;
    background: $white;
    &:hover {
      color: $white;
      background: $mine-shaft;
      border: solid 1px $mine-shaft;
    }
  }

  ::v-deep .btn {
    font-size: 1.4rem;
    text-transform: none;
  }
  ::v-deep .content-wrapper {
    padding: 0 2.4rem;
    text-align: center;

    @screen mdr {
      padding: 0;
    }
    .description {
      display: none;
    }
    p {
      font-weight: 300;
    }
  }

  #sgh-slider {
    &.multiple-slides {
      @screen md {
        max-width: 37.5rem;
      }
    }
  }

  @screen lg {
    padding-bottom: 8rem;
  }

  @screen md {
    .flex-1 {
      min-width: 0;
    }
  }

  @screen smr {
    ::v-deep .cta {
      white-space: break-spaces;
      line-height: 1.8rem;
    }
  }
}

.section {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $mercury;
    padding-bottom: 0.8rem;
    margin-bottom: 2.4rem;

    &.little-margin {
      margin-bottom: 1.6rem;
    }

    h3 {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 500;
      color: $black;
    }

    .simple-link {
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 300;
      text-decoration: underline;
      color: $black;

      .common__icon--arrow-right {
        margin-left: 0.8rem;
      }
    }

    .btn {
      font-size: 1.4rem;
      padding: 0 2rem;
      min-width: auto;
      max-width: 15rem;
      white-space: unset;
      line-height: 1.8rem;
      height: 4rem;
      display: inline-flex;
      align-items: center;
      &.cta-primary {
        padding: 1.2rem 2rem;
      }
    }
  }

  &__empty-description {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 300;
    margin: 0;
  }

  ::v-deep #sgh-slider {
    height: auto;
  }

  ::v-deep .wrap-single-slide,
  .wishlist-products {
    text-align: center;

    @screen lg {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 1.6rem;
    }

    .ma-wishlist__product {
      padding-bottom: 0.8rem;

      .sgh-tile {
        padding: 0;
      }

      .ratio-a {
        padding-top: 95%;
      }
    }
  }
}

.cms-text-module {
  padding-top: 2.4rem;
}

::v-deep {
  .banner-image-wrapper,
  .image-wrapper {
    flex: 0 0 50%;
  }
  .image-dimension {
    height: 242px;
    width: 100%;
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    padding: 4rem 2.4rem;

    &.perfectShade {
      @screen md {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      @screen mdr {
        & > .title {
          margin-top: 1.3rem;
        }
      }
    }

    > div > img {
      width: 6rem;
      height: 6rem;

      @screen md {
        margin-bottom: 1.95rem;
      }
    }

    & > .pretitle {
      color: $black;
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 1.95rem;
      text-transform: uppercase;
      font-weight: 300;

      @screen mdr {
        margin-top: 1.3rem;
      }
    }

    & > .title {
      color: $black;
      padding-bottom: 0.8rem;

      @screen mdr {
        margin-top: 2.4rem;
      }
    }

    & > .description {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    & > p {
      text-align: center;
    }

    .sgh-cms-product-tile {
      .picture {
        padding: 0;
        a,
        img {
          position: relative;
        }
        &__image {
          @screen lgr {
            width: 75%;
          }
        }
      }
      &:hover {
        .picture__image {
          opacity: 1;
        }
        .picture__image--hover {
          opacity: 0;
          display: none;
        }
      }
      .details a[data-description='OO9129_Cables_888392604569'] {
        @screen mdr {
          margin-bottom: 5.6rem;
        }
      }
    }

    & > .tile,
    .sgh-cms-product-tile {
      .original-price,
      .brand {
        font-size: 1rem;
        margin: 0 8px 0 0;
      }

      .brand {
        color: $black;
        font-weight: 700;
        text-transform: uppercase;
      }

      .current-price,
      .price__current {
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-bottom: 1.6rem;
        display: block;
      }

      .tile-image,
      .picture__wrapper {
        object-fit: cover;
        max-width: 100%;
        height: 9.5rem;
      }
    }

    & > .extra-content {
      margin-bottom: 2.4rem;
    }

    & > .cta {
      height: 4rem;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      margin-top: 1.6rem;
    }
  }

  .content-carousel .content-wrapper {
    padding: 24px 0 0;
    justify-items: center;

    & > .description {
      text-align: center;
      max-width: 348px;
    }
  }

  @media screen and (min-width: 768px) {
    .image-dimension {
      height: 284px;
      width: 364px;
    }

    .content-carousel .content-wrapper {
      padding: 0 24px;
    }
  }
}
