
@import '../../../styles/variables';
@import '../../../styles/fonts.scss';
.sgh-store-appointments-title {
  @include actaHeadlineFontTitle();
}
.cta-secondary {
  width: 19.2rem;
  color: $mine-shaft;

  &.btn {
    border-color: $mine-shaft;
  }
}
.cta-primary {
  &.btn {
    display: flex;
    align-items: center;
  }
}
.guide-tile {
  width: 14rem;
  &__icon {
    width: 4.1rem;
  }
}
.popup-overlay {
  background-color: #222222cc;
  backdrop-filter: blur(1rem);

  .booking-popup {
    max-width: 50rem;
  }
}
